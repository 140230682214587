import React from 'react'
import { Link } from 'gatsby'
import { Layout, Parallax, DelayedLink } from '@common'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import {
	page__wrapper,
	page__quote,
	page__parallax,
	page__parallax__inner,
	page__buttons,
	page__button,
	page__button__alt,
	page__testimonial,
	page__list,
	page__grid,
	page__grid__row,
} from './styles/page.module.scss'

const Help = ({ pageContext, location }) => (
	<Layout location={location} 
		page={pageContext.page}>
		<div className={page__wrapper}>
			<h1>
				<strong>Możesz bardzo pomóc</strong> za darmo
			</h1>
			<h3>
				Daj <strong>gwiazdkę repozytorium ze zbiorem</strong>, a jeśli
				widzisz mojego posta - zostaw lajka.{' '}
				<strong>Z góry dzięki!</strong>
			</h3>
			<div className={page__buttons}>
				<div className={`${page__button} ${page__button__alt}`}>
					<OutboundLink
						target='_blank'
						href='https://github.com/Przemocny/zbior-zadan-html-css-js-react'
					>
						Gwiazdka na Github
					</OutboundLink>
					<span>&gt;</span>
				</div>
				<div className={`${page__button}`}>
					<span>&lt;</span>
					<OutboundLink
						target='_blank'
						href='https://www.facebook.com/localhost40310'
					>
						Like dla Fanpage
					</OutboundLink>
				</div>
			</div>

			<h1>Lub dołącz do patronów mojej inicjatywy zyskując</h1>
			<ul>
				<li>
					<span>1</span>
					<h3>
						<strong>Dożywotni dostęp</strong> do zbiorów zadań{' '}
						<strong>Gladiatorów Javascriptu</strong>
					</h3>
				</li>
				<li>
					<span>2</span>
					<h3>
						Skarbnicę <strong>zaawansowanych materiałów</strong> do
						nauki i gotowego do wdrożenia kodu
					</h3>
				</li>
				<li>
					<span>3</span>
					<h3>
						<strong>Mentorską pomoc</strong> w osiąganiu kolejnych
						poziomów wtajemniczenia
					</h3>
				</li>
			</ul>

			<div className={page__buttons}>
				<div className={`${page__button}`}>
					<span>&gt;</span>
					<DelayedLink to={'/pricing/'}>A powiedz coś więcej</DelayedLink>
				</div>
			</div>


		</div>
	</Layout>
)

export default Help
